import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";

// services
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

import API from "@vahak/core/dist/_services/const";

// types
import {
    ClientHookInfiniteQueryOptions,
    ClientHookOptions,
    useVahakInfiniteQuery,
    useVahakMutationQuery,
    useVahakQuery
} from "@vahak/core/dist/hooks/useVahakQueryClient";
import { generateUrlParams } from "@vahak/core-ui/dist/methods/generateUrlParams";

/**
 * Get All CS Users with filters
 */
export interface GetCSUsersParams {
    roleType?: string;
    phoneNumber?: string;
    page?: number;
    limit?: number;
    managerUserId?: number;
}

export interface IManagerUserData {
    manager_details?: {
        id?: number;
        phone_number?: string;
        name?: string;
        role_ids?: string[];
    };
    manager_id?: number;
}

export interface CSUserData extends IManagerUserData {
    name?: string;
    phone_number?: string;
    user_id?: number;
    role_ids?: string[];
    created_time?: string;
    company_id?: number;
    limit?: number;
}

export interface GetCSUsersResponse {
    data: {
        total_pages: number;
        total_records: number;
        page_number: number;
        details: CSUserData[];
    };
}

const mapParamsToPayloadToGetCsUserData = (params: GetCSUsersParams) => {
    const payload = {
        page: params?.page,
        role_id: params?.roleType,
        p_n: params?.phoneNumber,
        l: params?.limit,
        manager_user_id: params?.managerUserId
    };
    return payload;
};

export const useGetCSUsers = (
    params: GetCSUsersParams,
    options: ClientHookInfiniteQueryOptions<GetCSUsersResponse, unknown, CSUserData>
) => {
    const { roleType, phoneNumber, limit, managerUserId } = params;
    const { GET } = useVahakHttpClient();

    function getCSUsers({ pageParam = 1 }) {
        const payload = {
            ...mapParamsToPayloadToGetCsUserData(params),
            page: pageParam
        };

        const urlString = generateUrlParams(payload);
        return GET<GetCSUsersResponse>(`${API.GET_CS_USERS}${urlString}`);
    }

    return useVahakInfiniteQuery<GetCSUsersResponse, unknown>(
        [QueryNames.dashboardUser.getCSUsers, roleType, phoneNumber, managerUserId],
        getCSUsers,
        {
            getNextPageParam: (lastPage, allPages) => {
                if (lastPage.data?.page_number < lastPage.data?.total_pages) return lastPage.data?.page_number + 1;
                return undefined;
            },
            ...options,
            onSuccess: (data) => {
                let aggregatedData: GetCSUsersResponse["data"]["details"] = [];
                data.pages.forEach((data) => {
                    aggregatedData = [...aggregatedData, ...(data?.data?.details ?? [])];
                });
                options.onSuccess(aggregatedData);
            }
        }
    );
};

export const useGetCSUsersMutation = () => {
    const { GET } = useVahakHttpClient();

    function getCSUsers(params: GetCSUsersParams) {
        const payload = mapParamsToPayloadToGetCsUserData(params);
        const urlString = generateUrlParams(payload);
        return GET<GetCSUsersResponse>(`${API.GET_CS_USERS}${urlString}`);
    }

    return useVahakMutationQuery<GetCSUsersResponse, unknown, GetCSUsersParams>(
        [QueryNames.dashboardUser.getCSUsers],
        getCSUsers
    );
};

/**
 * Get CS User additional data
 */

interface GetCSUserDetailsParams {
    userId?: number;
}

export interface GetCSUserDetailsResponse {
    data: {
        name: string;
        phone_number: string;
        role_id: string[];
        cover_picture: string;
        city: string;
        state: string;
        created_at: string;
        updated_at: string;
        manager_details?: IManagerUserData["manager_details"];
        lorry_type_ids?: number[];
    };
}

export const useGetCSUserDetails = (
    params: GetCSUserDetailsParams,
    options: ClientHookOptions<GetCSUserDetailsResponse, unknown, GetCSUserDetailsResponse["data"]>
) => {
    const { GET } = useVahakHttpClient();
    const { userId } = params;

    function getList({ pageParam = 1 }) {
        const payload = {
            user_id: userId,
            page: pageParam
        };
        const urlString = generateUrlParams(payload);
        return GET<GetCSUserDetailsResponse>(`${API.GET_CS_USER_DETAILS}${urlString}`);
    }
    return useVahakQuery<GetCSUserDetailsResponse, unknown, GetCSUserDetailsResponse["data"]>(
        [QueryNames.dashboardUser.getCSUserDetails],
        getList,
        {
            ...options,
            select: (data) => data.data
        }
    );
};
