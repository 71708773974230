/**
 * enum details available in https://vahak.atlassian.net/wiki/spaces/PR/pages/789938177/Call+logs+mapping
 */

export enum C2C_REFERENCE_TYPE {
    LOAD = 1,
    LORRY = 2,
    NETWORK = 3,
    TRANSACTION_LOAD_BID = 5,
    TRANSACTION_LORRY_BID = 6,
    LOAD_MY_BID_RECEIVED = 7,
    LOAD_MY_BID_SENT = 8,
    LORRY_MY_BID_RECEIVED = 9,
    LORRY_MY_BID_SENT = 10,
    PROFILE = 11,
    TRANSPORT_DIRECTORY = 12,
    AGENT_LOAD = 20,
    TAGGED_USER_LOAD_BID = 21,
    TAGGED_USER_LOAD_ENQUIRY_CARD = 22,

    TAGGED_USER_LOAD_LIVE_LORRY = 23,
    TAGGED_USER_LORRY_LIVE_LOAD = 24,
    ASSURED_LOAD_TAGGED_LIVE_LORRY = 25,
    TAGGED_USER_LOAD_MY_BID = 30,
    TAGGED_USER_LORRY_MY_BID = 35,

    LOAD_MARKETPLACE = 36,
    LORRY_MARKETPLACE = 37
}
