import { AppContext } from "@vahak/core/dist/app-context";
import { toast } from "@vahak/core/dist/components/toast/toast";
import { ReactNode, useContext } from "react";

// Translation
import { ga4sendEvent } from "@vahak/core/dist/_services/user-analytics.service";
import { useTranslation } from "next-i18next";
import router from "next/router";
import { CONTACT_DAILY_LIMIT, LOGIN } from "../constants/modal-strings";
import { useToggleModal } from "./useToggleModal";
import {
    newCustomer2CustomerCallbackRequest,
    NewCustomer2CustomerCallbackRequestProps
} from "@vahak/core/dist/_services/callBack.service";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { copyToClipboard } from "@vahak/core/dist/methods/copyToClipboard";
import CopyIcon from "@vahak/core/src/icons/copy.svg";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";

export function useGetContactDetails(transporterId: string) {
    const { t } = useTranslation(["transporters", "common"]);
    const { openModal: openLoginModal, closeModal: closeLoginModal } = useToggleModal(LOGIN);
    const { openModal: openLimitReachedModal, handleToggle: onToggleLimitReachedModal } =
        useToggleModal(CONTACT_DAILY_LIMIT);

    const { numOfContactRqsts, updateNumOfContactRqsts, transporterContactsRqstd, isPhoneVerified } =
        useContext(AppContext);

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    // const openLoginModal = () => {
    //     history.push({
    //         state: {
    //             ...history.location.state,
    //             isLoginModalVisible: true
    //         },
    //         search: history.location.search
    //     });
    // };

    const onToggleGetContactDetailsModal = () => {
        if (router.asPath.includes(LOGIN)) {
            return;
        } else {
            openLoginModal();
        }
    };

    const sendContactDetails = async ({
        callerId,
        calleeId,
        referenceId,
        referenceType
    }: NewCustomer2CustomerCallbackRequestProps) => {
        return await newCustomer2CustomerCallbackRequest({
            callerId,
            calleeId,
            referenceId,
            referenceType
        });
    };

    // const onClickGetContact = (companyPhoneNumber?: string) => {
    //     if (transporterContactsRqstd?.includes(transporterId)) {
    //         toast.info(t("toast.already_sent"));
    //     } else {
    //         if (numOfContactRqsts && numOfContactRqsts >= 10) {
    //             openLimitReachedModal();
    //         } else {
    //             if (numOfContactRqsts && numOfContactRqsts < 5)
    //                 ga4sendEvent("Directory_Total_contactdetails_web", { value: 1 });
    //             else ga4sendEvent("Directory_Total_contactdetails_web", { value: 0 });

    //             if (!isPhoneVerified) {
    //                 openLoginModal();
    //                 document.addEventListener(CUSTOM_EVENTS.LOGGEDIN, () => {
    //                     copyPhoneNum(companyPhoneNumber);
    //                 });
    //             } else {
    //                 copyPhoneNum(companyPhoneNumber);
    //             }
    //         }
    //     }
    // };

    const connectC2C = ({
        callerId,
        calleeId,
        referenceId,
        referenceType
    }: NewCustomer2CustomerCallbackRequestProps): any => {
        if (transporterContactsRqstd?.includes(transporterId)) {
            toast.info(t("toast.already_sent"));
        } else {
            if (numOfContactRqsts && numOfContactRqsts >= 10) {
                openLimitReachedModal();
            } else {
                if (numOfContactRqsts && numOfContactRqsts < 5)
                    ga4sendEvent("Directory_Total_contactdetails_web", { value: 1 });
                else ga4sendEvent("Directory_Total_contactdetails_web", { value: 0 });

                if (!isPhoneVerified) {
                    openLoginModal();
                } else {
                    sendContactDetails({
                        callerId,
                        calleeId,
                        referenceId,
                        referenceType
                    }).then((vNum) => {
                        if (vNum) {
                            if (isMobileScreen) window.open(`tel:${vNum}`, "_top");
                            else {
                                toast.success(
                                    <div
                                        style={{ display: "flex", alignItems: "center" }}
                                        onClick={() => copyToClipboard(vNum)}
                                    >
                                        Please call on &nbsp;<b>{vNum}</b>&nbsp;
                                        <IconWrapper svgFillColor="#fff" height={18} width={18}>
                                            <CopyIcon />
                                        </IconWrapper>
                                    </div>,
                                    {
                                        autoClose: 20000
                                    }
                                );
                            }
                        }
                    });
                }
            }
        }
    };

    return {
        onToggleGetContactDetailsModal,
        onToggleLimitReachedModal,
        // onClickGetContact,
        connectC2C
    };
}
