//Hooks
import { useVahakMutationQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";

// services
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

//Endpoints
import API from "@vahak/core/dist/_services/const";

// types

// Add User
interface Params {
    loadId: number;
    ticketType: number;
    phoneNumber: string;
}

interface Response {
    data?: {};
}

export const useLoadAssign = () => {
    const { POST } = useVahakHttpClient();
    function method(data: Params) {
        return POST<unknown, Response>(API.LOAD_REASSIGN, {
            load_id: data.loadId,
            ticket_type: data.ticketType,
            phone_number: data.phoneNumber
        });
    }

    return useVahakMutationQuery<Response, unknown, Params>([QueryNames.dashboardUser.reassignLoad], method);
};
