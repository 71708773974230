import React from "react";
import { IAgentDetail } from "@vahak/core/dist/custom-types/common-preloads";
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks/useToggleModalWithLocalState";
import AgentDetailsPopup from "./AgentDetailsPopup";
import { IAssignedAgentDetail } from "../../../services/load/useGetLoadMarketV2/type";
import AssignedCsPopup, { AssignedCsPayload } from "./AssignedCsPopup";
import Chip from "@vahak/core-ui/dist/components/Chip";

interface AgentBadgeBaseProps {
    type: "tso" | "cs";
}

interface TsoAgentBadgeProps extends AgentBadgeBaseProps {
    type: "tso";
    data?: IAgentDetail;
}
interface CsAgentBadgeProps extends AgentBadgeBaseProps {
    type: "cs";
    data?: IAssignedAgentDetail;
    payload?: AssignedCsPayload;
    allowAssignment?: boolean;
    onSuccess?: () => void;
}

type AgentBadgeProps = TsoAgentBadgeProps | CsAgentBadgeProps;

const AgentBadge = (props: AgentBadgeProps) => {
    const { type, data } = props;
    const { isModalOpen, toggleModal } = useToggleModalWithLocalState(false);
    switch (type) {
        case "cs": {
            const onClick = props.allowAssignment ? toggleModal : undefined;
            return (
                <>
                    {data?.name ? (
                        <Chip color="blue" size="auto" outlined radius={12} onClick={onClick}>
                            {data.name}
                        </Chip>
                    ) : (
                        <Chip color="grey" size="auto" outlined radius={12} onClick={onClick}>
                            Un-assigned
                        </Chip>
                    )}
                    {isModalOpen && props.onSuccess && (
                        <AssignedCsPopup
                            data={data}
                            isOpen={isModalOpen}
                            onToggle={toggleModal}
                            payload={props.payload}
                            onSuccess={props.onSuccess}
                        />
                    )}
                </>
            );
        }
        case "tso": {
            return data?.agent_name ? (
                <>
                    <Chip color="green" size="auto" outlined radius={12} onClick={toggleModal}>
                        {data?.agent_name}
                    </Chip>

                    {isModalOpen && <AgentDetailsPopup data={data} isOpen={isModalOpen} onToggle={toggleModal} />}
                </>
            ) : (
                <Chip color="grey" size="auto" outlined radius={12}>
                    Un-tagged
                </Chip>
            );
        }
    }
};

export default AgentBadge;
