import React, { useContext, useState } from "react";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import ModalOrBottomSheetWrapper, {
    ModalOrBottomSheetWrapperProps
} from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import Button from "@vahak/core-ui/dist/components/Button";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { toast } from "@vahak/core/dist/components/toast/toast";
// Methods
import { stringOperations } from "@vahak/core-ui/dist/methods/string";
import { generateSelectOption } from "../../../methods/uiUtil";
import { CSUserData, useGetCSUsersMutation } from "../../../services/admin-user/useGetCSUsers";
import { useLoadAssign } from "../../../services/ticket-system/useLoadAssign";
// Type
import { IAssignedAgentDetail } from "../../../services/load/useGetLoadMarketV2/type";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";
import SearchCsUserOption from "../dashboard-user/SearchCsUserOption";
import { AppContext } from "@vahak/core/dist/app-context";
import { formatDate, getRelativeTimeString } from "@vahak/core-ui/dist/methods/time";
import { DotSymbol } from "@vahak/core-ui/dist/constants/symbol";
import { getJoinedStringFromArray } from "@vahak/core-ui/dist/methods";
import { LoadTicketType } from "../ticket-system-helper/constants";
import { AdminRole } from "../../../constants/adminRoles";

export interface AssignedCsPayload {
    loadId?: number;
    ticketType?: LoadTicketType;
}
interface AssignedCsPopupProps extends Pick<ModalOrBottomSheetWrapperProps, "id" | "isOpen" | "onToggle"> {
    data?: IAssignedAgentDetail;
    payload?: AssignedCsPayload;
    onSuccess: () => void;
}

export const generateCsUserOption = async (str: string, callback: Function, csSearchMethod: Function) => {
    let phoneNumber: string | undefined = stringOperations(str, ["removeNonNumeric", "trim", "truncate:10"]);

    phoneNumber = phoneNumber.length === 10 ? phoneNumber : undefined;
    if (phoneNumber && csSearchMethod) {
        const data = await csSearchMethod({ phoneNumber });
        callback(getCsUserListOption(data?.data?.details ?? []));
    } else {
        callback([]);
    }
};

const getCsUserListOption = (list: CSUserData[]) => {
    return list.map((user) =>
        generateSelectOption([user.name, `(${user.phone_number})`].join(" "), user, user.user_id)
    );
};

export const getLoadAssignmentTimeStr = (data?: IAssignedAgentDetail, type: "long" | "short" = "long") => {
    return (
        !!data?.assigned_at &&
        getJoinedStringFromArray(
            [
                formatDate(data?.assigned_at, type === "long" ? "hh:mm a -  DD MMM, YYYY" : "h:mm a -  DD MMM, YY"),
                getRelativeTimeString(new Date(data?.assigned_at))
            ],
            ` ${DotSymbol} `
        )
    );
};
const AssignedCsPopup = ({ data, payload, id, isOpen, onToggle, onSuccess }: AssignedCsPopupProps) => {
    const context = useContext(AppContext);
    const { mutateAsync: reassignLoad } = useLoadAssign();

    const [selectedUser, setSelectedUser] = useState<CSUserData>();

    const onContinue = async () => {
        try {
            !!selectedUser?.phone_number &&
                !!payload?.loadId &&
                !!payload?.ticketType &&
                (await reassignLoad({
                    loadId: payload?.loadId,
                    phoneNumber: selectedUser.phone_number,
                    ticketType: payload?.ticketType
                }));

            onSuccess?.();
            onToggle?.(false);
        } catch (error) {
            toast.error((error as ApiErrorResponseType).message);
        }
    };

    return (
        <ModalOrBottomSheetWrapper titleText="Assigned To" {...{ id, isOpen, onToggle }} sheetBodyMinHeight={"20dvh"}>
            <Flex flexDirection="column" gap={32}>
                {!!data?.assigned_at && (
                    <Typography>
                        <Typography>Assigned At : </Typography>
                        <Typography>{getLoadAssignmentTimeStr(data)}</Typography>
                    </Typography>
                )}
                <Typography>
                    <Typography>Currently Assigned To : </Typography>
                    <Typography weight="medium">{data?.name ? data?.name : "None"}</Typography>
                </Typography>
                <div style={{ width: 400 }}>
                    <SearchCsUserOption
                        filter={{
                            limit: 10,
                            ...(payload?.ticketType === LoadTicketType.FULFILLMENT && {
                                roleType: AdminRole.FULLFILMENT_EXECUTIVE
                            }),
                            ...(payload?.ticketType === LoadTicketType.POST_PLACEMENT && {
                                roleType: AdminRole.IN_TRANSIT_EXECUTIVE
                            })
                        }}
                        onSelect={(assignedToCsUserData) => {
                            setSelectedUser(assignedToCsUserData);
                        }}
                        selectedUser={selectedUser}
                        searchProps={{
                            placeholder: "Search using Phone (8295 XXX XXX)",
                            label: "Select new assignee"
                        }}
                    />
                </div>

                <Flex justifyContent="flex-end">
                    <Button onClick={onContinue} disabled={!selectedUser}>
                        Update
                    </Button>
                </Flex>
            </Flex>
        </ModalOrBottomSheetWrapper>
    );
};

export default AssignedCsPopup;
