import React from "react";
import { IAgentDetailResponse } from "@vahak/core/dist/custom-types/common-preloads";
import ModalOrBottomSheetWrapper, {
    ModalOrBottomSheetWrapperProps
} from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import CallIcon from "@vahak/core/dist/icons/Call_CTA_BLUE.svg";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper/IconWrapper";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { ADMIN_OPERATION } from "../../../constants/adminOperation";

interface ExampleProps extends Pick<ModalOrBottomSheetWrapperProps, "id" | "isOpen" | "onToggle"> {
    data?: IAgentDetailResponse["agent_detail"];
}

const AgentDetailsPopup = ({ data, id, isOpen, onToggle }: ExampleProps) => {
    const phoneNumberShort = data?.phone_number?.replace("+91", "");
    const { checkPermission } = useCsDashboard();
    return (
        <ModalOrBottomSheetWrapper titleText="Agent Details" {...{ id, isOpen, onToggle }} sheetBodyMinHeight={"20dvh"}>
            <Flex flexDirection="column">
                <Typography>
                    <Typography>Name</Typography> : <Typography weight="semibold">{data?.agent_name}</Typography>
                </Typography>
                {checkPermission(ADMIN_OPERATION.userPhoneNumberVisibilityUI) && (
                    <Flex alignItems="center" gap={8}>
                        <Typography>Phone Number</Typography> :
                        <Typography weight="semibold" underline>
                            +91-{phoneNumberShort}
                        </Typography>
                        <IconWrapper
                            onClick={() => {
                                window.open(`tel:${data?.phone_number}`, "_blank", "noopener noreferrer");
                            }}
                        >
                            <CallIcon />
                        </IconWrapper>
                    </Flex>
                )}
            </Flex>
        </ModalOrBottomSheetWrapper>
    );
};

export default AgentDetailsPopup;
