export const SERVICE_TYPE_MODAL = "ServiceTypeModal";
export const CONFIRM_CANCEL_MODAL = "ConfirmCancelModal";
export const LOAD_BID = "loadbid";
export const LORRY_BID = "lorrybid";
export const SUPPORT_MENU = "SupportMenu";
export const CALL_BACK_REQUEST = "CallBackReq";
export const CONTACT_US_CALL_BACK_REQUEST = "ContactUsCallBack";
export const MOBILE_FILTERS_MARKETPLACE = "Filters";
export const SRC_SEARCH_VISIBLE = "SourceSearchVisible";
export const DEST_SEARCH_VISIBLE = "DestinationSearchVisible";
export const HOMEPAGE_SRC_SEARCH_VISIBLE = "SourceSearch";
export const HOMEPAGE_DEST_SEARCH_VISIBLE = "DestinationSearch";
export const CONTACT_DAILY_LIMIT = "contact-daily-limit";
export const LOGIN = "Login";
export const LOAD_POSTING_FORM = "LoadPostingForm";
export const LORRY_POSTING_FORM = "LorryPostingForm";
export const SHARE_MODAL = "ShareModal";
