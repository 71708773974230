import React, { useState } from "react";
import SearchProps from "@vahak/core-ui/dist/components/Search/Search.types";
import {
    CSUserData,
    GetCSUsersParams,
    useGetCSUsers,
    useGetCSUsersMutation
} from "../../../services/admin-user/useGetCSUsers";
import Search from "@vahak/core-ui/dist/components/Search";
import { generateSelectOption } from "../../../methods/uiUtil";
import { OnChangeSelectHandlerType, SelectOnChangeType, SelectOption } from "@vahak/core-ui/dist/components/Select";
import { stringOperations } from "@vahak/core-ui/dist/methods/string";
import { CSSObject } from "styled-components";
import { NoSuccessErrorHandler } from "@vahak/core/dist/constants/api";

interface SearchCsUserOptionProps {
    searchProps?: Partial<SearchProps>;
    wrapperClassName?: string;
    filter?: GetCSUsersParams;
    inlineStyle?: CSSObject;
    onSelect?: (user?: CSUserData) => void;
    selectedUser?: CSUserData;
}

const SearchCsUserOption = ({
    searchProps,
    wrapperClassName,
    inlineStyle,
    filter,
    onSelect,
    selectedUser
}: SearchCsUserOptionProps) => {
    const selectedOption = selectedUser ? generateSelectOption(selectedUser?.name ?? "", selectedUser) : undefined;
    const [phoneNumberText, setPhoneNumberText] = useState("");

    const { mutateAsync: getCsUserList } = useGetCSUsersMutation();

    const getCsUserListOption = (list: CSUserData[] = []) => {
        return list.map((user) => generateSelectOption(user.name ?? "", user));
    };

    const { isFetching, hasNextPage, fetchNextPage, data } = useGetCSUsers(
        {
            ...filter
        },
        {
            ...NoSuccessErrorHandler,
            refetchOnWindowFocus: false,
            cacheTime: Infinity,
            staleTime: Infinity
        }
    );
    const users = getCsUserListOption(data?.pages?.flatMap((p) => p?.data?.details));

    const onChangeSelectInput: OnChangeSelectHandlerType = (newValue) => {
        onSelect?.(newValue?.value);
    };

    const getPhoneNumberFroText = (str: string) => {
        return stringOperations(str, ["removeNonNumeric", "trim", "truncate:10"]);
    };

    const onChangeSearchInput = (str: string) => {
        setPhoneNumberText(getPhoneNumberFroText(str));
    };

    return (
        <span className={wrapperClassName} style={inlineStyle}>
            <Search
                loadOptions={async (input, cb) => {
                    const phoneNumber = getPhoneNumberFroText(input);
                    if (phoneNumber?.length === 10) {
                        const data = await getCsUserList({ phoneNumber });
                        cb(getCsUserListOption(data?.data?.details ?? []));
                    } else {
                        cb([]);
                    }
                }}
                isLoading={isFetching}
                inputValue={phoneNumberText}
                onInputChange={onChangeSearchInput}
                value={selectedOption}
                defaultOptions={users}
                onChange={onChangeSelectInput as SelectOnChangeType}
                option={[]}
                placeholder="Select User"
                onMenuScrollToBottom={() => {
                    hasNextPage && fetchNextPage();
                }}
                noOptionText="No Users"
                isClearable
                required
                {...searchProps}
            />
        </span>
    );
};

export default SearchCsUserOption;
