import { getPathName } from "../methods/getPathName";
import router from "next/router";

export function useToggleModal(modalName: string) {
    const handleToggle = () => {
        if (!router.asPath.includes(modalName)) {
            openModal(modalName);
        } else {
            router.push({ pathname: getPathName(), query: { ...router.query }, hash: undefined }, undefined, {
                shallow: true
            });
        }
    };

    const openModal = (currentModalName?: string) => {
        modalName = currentModalName || modalName;
        const currentHash = router.asPath.split("#").slice(1).join("#");

        if (currentHash && !currentHash.includes(modalName)) {
            router.push(
                {
                    pathname: getPathName(),
                    query: { ...router.query },
                    hash: `${currentHash}#${modalName}`
                },
                undefined,
                {
                    shallow: true
                }
            );
        } else {
            router.push({ pathname: getPathName(), query: { ...router.query }, hash: modalName }, undefined, {
                shallow: true
            });
        }
    };

    const closeModal = (currentModalName?: string) => {
        modalName = currentModalName || modalName;
        const numOfModalsOpen = router.asPath.split("#").length - 1;
        const currentHash = router.asPath.split("#").slice(1).join("#");

        if (numOfModalsOpen > 1) {
            router.push(
                {
                    pathname: getPathName(),
                    query: { ...router.query },
                    hash: currentHash.replace(`#${modalName}`, "")
                },
                undefined,
                {
                    shallow: true
                }
            );
        } else {
            router.push({ pathname: getPathName(), query: { ...router.query }, hash: undefined }, undefined, {
                shallow: true
            });
        }
    };

    return {
        handleToggle,
        openModal,
        closeModal
    };
}
